<template>
  <div
    class="achievement_sibling_navbox main_padding"
    :class="[{gray: bgcolorType === 'gray'}]"
    v-if="siblingData.length">
      <div class="itemlist w1400">
        <router-link
          class="item"
          :to="item.route"
          v-for="(item, i) in siblingData"
          :key="`AchievementSiblingNavbox_${i}`">
            <div class="left">
              <h6 class="txt-bold">{{item.title}}</h6>
              <p class="txt-gray txt-up">{{item.subTitle}}</p>
            </div>
            <div class="arrow_circle"></div>
        </router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'AchievementSiblingNavbox',
  props: {
    siblingData: {
      type: Array,
      default: () => [
        {
          title: 'Default Title',
          subTitle: '預設標題',
          route: '',
        },
      ],
    },
    bgcolorType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
};
</script>
