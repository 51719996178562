<template>
  <section id="achievement">
    <TopBanner
      v-if="nowPageData && $route.name === 'AchievementPast'"
      :mainBg="nowPageData.mainBg"
      :pageName="nowPageData.pageName"
      :titleWord="nowPageData.titleWord"
      :bottomBgColor="nowPageData.bottomBgColor"/>

    <router-view
      :nowPageData="nowPageData"/>

    <AchievementSiblingNavbox
      :siblingData="siblingData"
      :bgcolorType="(()=>{
        return $route.name === 'AchievementPast' ?
        ''
        : 'gray'
      })()"/>
  </section>
</template>

<script>
import { achievementPageData } from '@/utils/achievementPageData';
import TopBanner from '@/components/public/TopBanner.vue';
import AchievementSiblingNavbox from '@/components/page/achievement/AchievementSiblingNavbox.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Achievement',
  mixins: [titleMixin],
  components: {
    TopBanner,
    AchievementSiblingNavbox,
  },
  data() {
    return {
      achievementPageData,
    };
  },
  computed: {
    nowPageData() {
      return achievementPageData.find(
        (item) => item.route.name === this.$route.name,
      );
    },
    siblingData() {
      return achievementPageData
        .filter((item) => item.route.name !== this.$route.name)
        .map((item) => ({
          title: item.pageName,
          subTitle: item.pageNameEn,
          route: item.route,
        }));
    },
  },
};
</script>

<style
  scoped
  src="@/assets/scss/achievement.scss"
  lang="scss">
</style>
