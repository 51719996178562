import achievementBanner from '../assets/img/achievement/01_banner.jpg';
import myanmar01 from '../assets/img/achievement/in/myanmar.png';
// import taiwan01 from '../assets/img/achievement/in/taiwan.png';
// import taiwan02 from '../assets/img/achievement/in/taiwan2.png';
import vietnam01 from '../assets/img/achievement/in/vietnam.png';
import koike01 from '../assets/img/achievement/in/koike.png';

export const achievementPageData = [
  {
    route: {
      name: 'AchievementPast',
      params: { page: 1 },
    },
    pageName: '歷年實績',
    pageNameEn: 'Actual Achievement',
    mainBg: achievementBanner,
    titleWord: [
      {
        word: 'Actual',
        ifBold: true,
      },
      {
        word: 'Achievement',
        ifBold: false,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
  {
    route: { name: 'Myanmar' },
    pageName: '緬甸啤酒廠',
    pageNameEn: 'MYANMAR BREWERY LIMITED',
    introduction: [
      {
        title: '緬甸啤酒工廠-生產能力增設',
        point: [
          {
            name: '用途',
            info: '緬甸啤酒生產能力增設',
          },
          {
            name: '業務範圍',
            info: '麥芽倉、原料處理、糖化、發酵儲藏、包裝、熱能回收',
          },
          {
            name: '地區',
            info: '緬甸',
          },
          {
            name: '製造品項',
            info: '緬甸啤酒、麒麟一番搾',
          },
          {
            name: '分類',
            info: '啤酒',
          },
          {
            name: '施工面積',
            info: '約40,000㎡',
          },
          {
            name: '施工日期',
            info: '2018年1月~2019年12月',
          },
        ],
        info: [
          {
            title: '專案內容',
            infoText: '位於緬甸仰光的緬甸麒麟啤酒工廠，在既設的生產設備為基礎提升生產線的產能並優化節省能源，該項目從原本的35萬kl/年提升到40萬kl/年的生產能力。日商麒麟技術主要負責麥芽倉、原料處理、糖化、發酵儲藏、包裝、熱能回收系統等。日商麒麟技術除了做設計規劃，並帶領施工團隊投入現場，從安裝、測試、直到開始正式投料生產。40萬kl/年生產能力增設於2019年12月完成，我們致力於提供客戶一個高效率、安全的工廠，讓客戶能夠順利地生產。',
            infoPic: myanmar01,
          },
        ],
      },
    ],
  },
  {
    route: { name: 'Vietnam' },
    pageName: '越南麒麟飲料公司',
    pageNameEn: 'VIETNAM KIRIN BEVERAGE CO., LTD.',
    introduction: [
      {
        title: '越南麒麟飲料-無菌PET瓶裝線',
        point: [
          {
            name: '用途',
            info: '飲料製造設備',
          },
          {
            name: '業務範圍',
            info: '液體處理單元、高溫殺菌機、調配處理單元和包裝線',
          },
          {
            name: '地區',
            info: '越南',
          },
          {
            name: '製造品項',
            info: '茶、風味水',
          },
          {
            name: '分類',
            info: '飲料',
          },
          {
            name: '施工面積',
            info: '15,000㎡',
          },
          {
            name: '竣工日期',
            info: '2021年6月',
          },
        ],
        info: [
          {
            title: '專案內容',
            infoText: '位於越南美福工業區的越南麒麟飲料工廠，此新設瓶裝線於2020年6月開始至2021年6月結束，該項目是越南麒麟飲料公司的第二條全新瓶裝線，日商麒麟技術主要負責液體處理單元、高溫殺菌機、調配處理單元和包裝線。有鑑於填料、土建、室內建築和桶槽遷入的界面問題，日商麒麟技術作為當中協調者，不斷與其他承包商協商合作，最終將完整產線移交給越南麒麟飲料。',
            infoPic: vietnam01,
          },
        ],
      },
    ],
  },
  {
    route: { name: 'Taiwan' },
    pageName: '台灣菸酒公司',
    pageNameEn: 'TAIWAN TOBACCO AND LIQUOR CORPORATION',
    introduction: [
      {
        title: '臺灣菸酒股份有限公司屏東酒廠-洗瓶機、殺菌床設備新設',
        point: [
          {
            name: '用途',
            info: '包裝線增設工程',
          },
          {
            name: '業務範圍',
            info: '包裝工場舊有洗瓶機及殺菌機拆除後安裝全新全自動洗瓶機及殺菌機(含附屬設備)',
          },
          {
            name: '地區',
            info: '台灣屏東內埔',
          },
          {
            name: '製造品項',
            info: '米酒生產線',
          },
          {
            name: '分類',
            info: '酒類',
          },
          {
            name: '施工面積',
            info: '約660 ㎡',
          },
          {
            name: '竣工日期',
            info: '2020 年 05 月',
          },
        ],
        info: [
          {
            title: '專案內容',
            infoText: `位於台灣屏東內埔工業區的臺灣菸酒公司屏東酒廠，
             此新設備於2020年5月開始至2021年05月結束。此項目為了因應設備老舊，操作及保養不易而採購全新自動洗瓶機及殺菌機。
             洗瓶機配合瓶型多樣化、採用自動控制減少人力需求。日商麒麟技術主要負責將既有舊設備拆卸，
             因應操作需求重新規劃設備配置及輸送，其包含土木基礎施作、沿用既有的配管配線銜接及地面恢復Epoxy等工程，最終如期完成驗收並將完整產線交由客戶。`,
            // infoPic: taiwan01,
          },
        ],
      },
      {
        title: '臺灣菸酒股份有限公司屏東酒廠-米酒生產線',
        point: [
          {
            name: '用途',
            info: '包裝線增設工程',
          },
          {
            name: '業務範圍',
            info: '包裝設備規劃、GMP無塵室設置、公用系統及舊設備拆卸',
          },
          {
            name: '地區',
            info: '台灣屏東內埔',
          },
          {
            name: '製造品項',
            info: '米酒生產線',
          },
          {
            name: '分類',
            info: '酒類',
          },
          {
            name: '竣工日期',
            info: '2013 年 02 月',
          },
        ],
        info: [
          {
            title: '專案內容',
            infoText: `位於台灣屏東內埔工業區的臺灣菸酒公司屏東酒廠，此新包裝線於2011年12月開始至2013年02月結束。
             此項目為了因應米酒市場需求增加，而擴大米酒產量，提升包裝品質及包裝產量的緣由，擬增設整條線全自動化米酒包裝設備，
             以增進包裝生產效率、降低操作成本，增進競爭力。日商麒麟技術主要負責將既有舊設備拆卸，重新規劃480支/分包裝設備，GMP無塵室，
             公用系統之水、電、氣規劃，安裝環境之照明、地面、空調管線等改善工程。日商麒麟技術作為工程承攬者與設備商、建築師、土木施工、電力規劃、配管工程
             等承包商協力合作，最終完成驗收將完整產線移交給屏東酒廠，並滿足客戶需求。`,
            // infoPic: taiwan02,
          },
        ],
      },
    ],
  },
  {
    route: { name: 'Koike' },
    pageName: '越南湖池屋公司',
    pageNameEn: 'KOIKE-YA VIETNAM CO. LTD.',
    introduction: [
      {
        title: '越南湖池屋-洋芋片(PC)、玉米條(SC)含包裝線 新廠建設工程',
        point: [
          {
            name: '用途',
            info: '食品製造、包裝設備',
          },
          {
            name: '業務範圍',
            info: 'PC線-原料前處理單元.油炸機.調味.輸送單元.澱粉回收及包裝線 SC線-擠出機.油炸機.調味.輸送單元及包裝線',
          },
          {
            name: '地區',
            info: '越南',
          },
          {
            name: '製造品項',
            info: 'PC、SC',
          },
          {
            name: '分類',
            info: '食品',
          },
          {
            name: '施工面積',
            info: '1,100㎡',
          },
          {
            name: '竣工日期',
            info: '2017年12月',
          },
        ],
        info: [
          {
            title: '專案內容',
            infoText: '位於越南同奈的越南麒麟飲料工廠，本專案始於2016年4月開始至2017年12月結束，是湖池屋公司的在越南新設的第一座食品廠，日商麒麟技術主要負責PC線-原料前處理單元、油炸機、調味、輸送單元、澱粉回收及包裝線；SC線-擠出機、油炸機、調味、輸送單元及包裝線。 由於產線包含國外及本國設備連結而有多方界面問題，日商麒麟技術作為系統整合的角色，給予廠商技術上建議並且在設備商之間居中協調，最終能夠交機結案，讓客戶順利且安心的生產。',
            infoPic: koike01,
          },
        ],
      },
    ],
  },
];

export const temp = [];
